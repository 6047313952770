@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* Firefox scrollbar settings */
  scrollbar-width: thin;
  scrollbar-color: #3f3f46 #181818;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #181818;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #181818;
}

body::-webkit-scrollbar-thumb {
  background-color: #27272a;
}
